const hostItems = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.') : window.location.hostname.split('.').slice(1);
const apiHost = hostItems.join('.');

const config = {
  apiUrl:         `${window.location.protocol}//${process.env.VUE_APP_API_PREFIX}${apiHost}${process.env.VUE_APP_API_PORT}/`,
  dateFormat:     'MM-DD-YYYY',
  dateTimeFormat: 'MM/DD/YYYY h:mm:ss A',
  timeFormat:     'HH:mm:ss',
};

export default config;
